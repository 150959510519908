import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

function Header({ location }) {

  return (<header
    style={{
      width: `90%`,
      margin: `0 auto`,
      padding: `var(--space-4) var(--size-gutter)`,
      display: `flex`,
      alignItems: `center`,
      justifyContent: `space-between`,
    }}
  >
    {location.pathname !== "/" && 
    <Link
      to="/"
      style={{
        color: `var(--color-text)`,
        fontSize: `var(--font-xl)`,
      }}
    >
      <h2>eb</h2>
    </Link>
    
    }
  </header>)
  
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
